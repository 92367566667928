import numeral from 'numeral';
import CryptoJS from 'crypto-js';
import { keyEcncrypt } from './globalConfig';
import {handleMappingMessage} from './MappingMessage';
import { logoutSaga } from '../store/sagas/auth';
// import {useHistory}                 from 'react-router-dom';
// import * as pathmenu           from './pathMenu';

export const inputToThousand = (amount) => {
    if (amount !== "0") {
        if (amount[0] === "0") {
            amount = amount.slice(1, amount.length);
        }
    }

    amount = amount.split(".").join("").replace(/[^0-9.]/g,'');
    return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const inputToNum = (amount) =>{
  return amount.replace(/[^0-9.]/g, '');
};

export const cekAndGetValueIdEKTP = (IdEKTP) => {
    let hasil = new Map();
    try{
        var currDate = new Date();
        var currYear = currDate.getFullYear();
        var gender = '';
        var isvalidIdKTP = false;
        var dob = '';
        var codeprov = '';
        var codereg = '';
        var codedist = '';
        if (IdEKTP.length === 16) {
            codeprov = IdEKTP.substring(0, 2);

            codereg = IdEKTP.substring(2, 4);
            codedist = IdEKTP.substring(4, 6);
            //secara format IDKTP sesuai kemendagri, urutan ke 3 sama 4 = kode kota,tapi di database simpool kodenya digabung dengan haprovinsicode
            codereg = codeprov+codereg;
            codedist = codereg+codedist;

            var cekdateDD = parseInt(IdEKTP.substring(6, 8));

            var getdate = '';
            var getmonth = '';
            var getyear = '';
            if(cekdateDD > 31){
                cekdateDD = cekdateDD - 40;
                if(cekdateDD <= 31 && cekdateDD > 0){
                    gender = 'F';
                    isvalidIdKTP = true;
                    
                }else{
                    isvalidIdKTP = false;
                }
            }else{
                dob = IdEKTP.substring(6, 12);
                isvalidIdKTP = true;
                gender = 'M'
            }
            console.log('isvalidIdKTP A ',isvalidIdKTP);

            if(cekdateDD > 9){
                getdate = cekdateDD;
            }else{
                getdate = "0"+cekdateDD;
            }
            getmonth = parseInt(IdEKTP.substring(8, 10));
            if(getmonth > 12){
                isvalidIdKTP = false;
            }
            console.log('isvalidIdKTP B ',isvalidIdKTP);
            var strgetyear = IdEKTP.substring(10, 12);
            getyear = parseInt(strgetyear);

            let twoDigitYearsAhead = new String(currYear).substring(0,2);
			let twoDigitYearsBehind = new String(currYear).substring(2,4);

            if(parseInt(strgetyear) > parseInt(twoDigitYearsBehind) ) {
				getyear = (parseInt(twoDigitYearsAhead)-1) + strgetyear;
			}else {
				getyear = twoDigitYearsAhead + strgetyear;
            }
            dob = getdate+" "+NumMonthToNameMonthInEnglish(getmonth)+" "+getyear;
            
        }
        var dobdate = Date.parse(dob);
        hasil.set('gender',gender);
        hasil.set('valididktp',isvalidIdKTP);
        hasil.set('dob',dob);
        hasil.set('longdob',dobdate);
        hasil.set('codeprov',codeprov);
        hasil.set('codereg',codereg);
        hasil.set('codedist',codedist);
        //codedist
    }catch(err){
        hasil.set('valididktp',false);
    }
    return hasil;
}
export const longToDate = (datelong) => {
    var tempdate = new Date(datelong);
    var month = (tempdate.getMonth() + 1);
    if(month < 10){
        month = "0"+month
    }
    var date = tempdate.getDate()
    if(date < 10){
        date = "0"+date;
    }
    return tempdate.getFullYear() + "-" + month + "-" + date
}
export const NumMonthToNameMonthInEnglish = (num) => {
    const numInt = parseInt(num);
    var namemonth = '';
    if(numInt == 1){
        namemonth = 'January';
    }else if(numInt == 2){
        namemonth = 'February';
    }else if(numInt == 3){
        namemonth = 'March';
    }else if(numInt == 4){
        namemonth = 'April';
    }else if(numInt == 5){
        namemonth = 'May';
    }else if(numInt == 6){
        namemonth = 'June';
    }else if(numInt == 7){
        namemonth = 'July';
    }else if(numInt == 8){
        namemonth = 'August';
    }else if(numInt == 9){
        namemonth = 'September';
    }else if(numInt == 10){
        namemonth = 'October';
    }else if(numInt == 11){
        namemonth = 'November';
    }else if(numInt == 12){
        namemonth = 'December';
    }
    return namemonth;
};
export const NameMonthToNum = (namemonth) => {
    var numInt = 0;
    if(namemonth == 'Jan'){
        numInt = 1;
    }else if(namemonth == 'Feb'){
        numInt = 2;
    }else if(namemonth == 'Mar'){
        numInt = 3;
    }else if(namemonth == 'Apr'){
        numInt = 4;
    }else if(namemonth == 'Mei'){
        numInt = 5;
    }else if(namemonth == 'Jun'){
        numInt = 6;
    }else if(namemonth == 'Jul'){
        numInt = 7;
    }else if(namemonth == 'Agu'){
        numInt = 8;
    }else if(namemonth == 'Sept'){
        numInt = 9;
    }else if(namemonth == 'Okt'){
        numInt = 10;
    }else if(namemonth == 'Nov'){
        numInt = 11;
    }else if(namemonth == 'Des'){
        numInt = 12;
    }
    if(numInt < 10){
        numInt = '0'+numInt;
    }
    return numInt;
};

export const NumMonthToNameMonth = (num) => {
    const numInt = parseInt(num);
    var namemonth = '';
    if(numInt == 1){
        namemonth = 'Jan';
    }else if(numInt == 2){
        namemonth = 'Feb';
    }else if(numInt == 3){
        namemonth = 'Mar';
    }else if(numInt == 4){
        namemonth = 'Apr';
    }else if(numInt == 5){
        namemonth = 'Mei';
    }else if(numInt == 6){
        namemonth = 'Jun';
    }else if(numInt == 7){
        namemonth = 'Jul';
    }else if(numInt == 8){
        namemonth = 'Agu';
    }else if(numInt == 9){
        namemonth = 'Sept';
    }else if(numInt == 10){
        namemonth = 'Okt';
    }else if(numInt == 11){
        namemonth = 'Nov';
    }else if(numInt == 12){
        namemonth = 'Des';
    }
    return namemonth;
};
export const numToMoneyWithComma = (amount) =>{
    let string = numeral(amount).format('0,0.00');
    let befcomma = string.split('.')[0].replaceAll(',','.');
    let aftercomma = string.split('.')[1];
    string = befcomma+','+aftercomma;
    return string;
    // console.log('numToMoneyWithComma ',amount)
    // if(amount.includes('.')){
    //     let aftercomma = amount.split('.')[1];
    //     if(aftercomma == ''){
    //         return amount;
    //     }
    // }
    // if(string.includes('.')){
    //     let aftercomma = string.split('.')[1];
    //     console.log('aftercomma ',aftercomma);
    //     if(aftercomma == '00'){
    //         return string.split('.')[0].replaceAll(',','.');
    //     }else{
    //         let nilai = string.split('.')[0].replaceAll(',','.');
    //             nilai += ","+aftercomma;
    //         return nilai;
    //     }
    // }
}

export const formatMoney = (amount) => {
        const returnamount = amount;
        amount = amount.replaceAll('.','');
        let aftercomma = '';
        if(amount.includes(',')){
            aftercomma = ','+amount.split(',')[1];
            amount = amount.split(',')[0];
        }
        if(amount.length > 3){
            let sisabagi = amount.length % 3;
            let isFirst = true;
            let val = '';
            let count = 0;
            for(var i=0; i< amount.length; i++){
                val += amount.charAt(i);
                count++;
                if((i == sisabagi - 1) && isFirst){
                    val += ".";
                    isFirst = false;
                    count = 0;
                }else if(count == 3 && i !== amount.length - 1){
                    val += ".";
                    count = 0;
                }
                
            }
            return val+aftercomma;
        }else{
            return returnamount;
        }
  };
export const numToMoney = (amount) =>{
    
    if(amount !== null && !isNaN(amount)){
            const amountInt = parseInt(amount);
            const amountNum = amount.toFixed(2);
            const amountStr = amountNum.toString();
            let afterComma;

            if (amountStr.includes('.')) {
                afterComma = amountStr.slice(amountStr.length - 2, amountStr.length);
            }

            if (afterComma != null) {
                if (afterComma === "00") {
                    return amountInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    return amountInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "," + afterComma;
                }
            } else {
                return amountInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        }else{
            return '0'
        }
};
export const inputJustNumberAndCommaDot = (temp) =>{
    let lengthVal = temp.length;
    let endchar = temp.charAt(lengthVal-1);
    if(endchar === ',' || !isNaN(endchar) || endchar === '.'){
        return true
    }else{
        return false;
    }
}
export const inputJustNumberAndComma = (temp) =>{
    var reg = /^-?\d+(?:\,\d+)?$/;
    let flagReg = reg.test(temp);
    if(temp.split(",").length > 1){
        if(temp.split(",")[0] === ''){
            flagReg = false;
        }
        if(temp.split(",")[0] !== '' && temp.split(",")[1] === ''){
            flagReg = true;
        }
    }
    if(temp === ''){
        flagReg = true;
    }
    return flagReg;
};

export const formatNpwp = (value) => {
    if (typeof value === 'string') {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6');
    }else{
        return value;
    }
};

export const listTypeReport = (param) => {
    var tempOutPut = [];
    
    tempOutPut.push({"value":"HTML","label":"Preview Format","typeapi":"text/html"});
    let flagexcel = true;
    let flagcsv = false;
    let flagpdf = true;
    if(param !== undefined){
        let isExcelHide = param.isExcelHide != undefined?param.isExcelHide:false;
        let isCSVHide = param.isCSVHide != undefined?param.isCSVHide:true;
        let isPDFHide = param.isPDFHide != undefined?param.isPDFHide:true;

        flagpdf = isPDFHide;
        
        if(isExcelHide){
            flagexcel = false;
        }
        if(isCSVHide){
            flagcsv = false;
        }else{
            flagcsv = true;
        }
    }
    if(flagexcel){
        tempOutPut.push({"value":"XLSX","label":"Excel Format","typeapi":"application/vnd.ms-excel"});
        tempOutPut.push({"value":"XLS","label":"Excel 97-2003 Format","typeapi":"application/vnd.ms-excel"});    
    }
    if(flagpdf){
        tempOutPut.push({"value":"PDF","label":"PDF Format","typeapi":"application/pdf"});
    }
    if(flagcsv){
        tempOutPut.push({"value":"CSV","label":"CSV Format (Table)","typeapi":"text/html"});
    }

    return tempOutPut;
}

export const toLogout = (error) =>{
    const errNet = 'Network Error';
    // console.log('toLogout ',error.toJSON());
    if(error.message){
        if(error.message == errNet){
            localStorage.removeItem('token');
            // localStorage.setItem('tenant', tenantID);
            // let tenantID = localStorage.getItem('tenant');
            // localStorage.clear();
            // sessionStorage.clear();
            // localStorage.setItem('tenant', tenantID);
            window.location.href = '/logout';
        }
    }
}

export const endOfMonth = (date)  =>{   
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
 
  }

// Mother Maiden Name = Ibu + Fullname
// Religion = “UNDISCLOSED”
// Tax# = 999999999999999 (15 digit)
// Tax Name = “UNDISCLOSED”
// Tax Address = “UNDISCLOSED”
// Birth Place = “UNDISCLOSED”
// Village = “UNDISCLOSED”
// RT = 99
// RW = 99
// Postal Code = 99999
// Province = 9999
// City = 9999
// Birth-date = 31/12/1900
// Subdistrict = "UNDISCLOSED"
  export const isMemberRegistred = (data)  =>{
    let value = true;
    let ibu = data.get("ibu").replaceAll(" ","");
    let fullname = data.get("fullname");
    let religion = data.get("religion");
    let tax = data.get("tax");
    let taxname = data.get("taxname");
    let taxaddress = data.get("taxaddress");
    let birthplace = data.get("birthplace");
    let village = data.get("village");
    let rt = data.get("rt");
    let rw = data.get("rw");
    let postalcode = data.get("postalcode");
    let province = data.get("province");
    let city = data.get("city");
    let birthdate = data.get("birthdate");
    let subdistrict = data.get("subdistrict");
    let typeidentity = data.get("typeidentity");
    
    if(
        (ibu.toLowerCase() === "ibu"+fullname.replaceAll(" ","").toLowerCase()) ||
        (religion.toLowerCase() === 'UNDISCLOSED'.toLowerCase()) ||
        (tax === '999999999999999') ||
        (taxname.toLowerCase() === 'UNDISCLOSED'.toLowerCase()) ||
        (taxaddress.toLowerCase() === 'UNDISCLOSED'.toLowerCase()) ||
        (birthplace.toLowerCase() === 'UNDISCLOSED'.toLowerCase()) ||
        (village.toLowerCase() === 'UNDISCLOSED'.toLowerCase()) ||
        // (rt === '99') ||
        // (rw === '99') ||
        (postalcode === '99999') ||
        (province === '9999') ||
        (city === '9999') ||
        (birthdate === '31 Desember 1900' && typeidentity !== "IC") ||
        (subdistrict.toLowerCase() === 'UNDISCLOSED'.toLowerCase())
      ){
        value = false;
    }
    return value;
  }

  export const versionProject = ()  =>{
    // let name = 'simpoolv3'
    // let version = '1.0.0' //demo
    // // let version = '1.0.0' //prod
    // const last_version = localStorage.getItem(`${name}-Version`);
    // console.log("last_version ",last_version);
    // if(last_version !== version){
    //     console.log('New Version Available !')
    //     localStorage.setItem(`${name}-Version`, version)
    //     window.location.href = '/logout';
    //     window.location.reload(true);
    // }
  }

  export default function emptyCache(){
    if('caches' in window){
    caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                console.log('emptyCache ',name);
                // caches.delete(name);
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        // window.location.reload(true);
    }
}

export const getPermissions = ()  =>{
    const permissionenc = localStorage.getItem('permissions') ? localStorage.getItem('permissions'):[];
    try{
        const bytes = CryptoJS.AES.decrypt(permissionenc, keyEcncrypt);
        const permissionsuser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let idx = permissionsuser.indexOf('');
        return permissionsuser;
    }catch(err){
        // window.location.href = '/';
        return [];
    }
}
export const getPortalMenuPermissions = ()  =>{
    const permissionenc = localStorage.getItem('portalmenu') ? localStorage.getItem('portalmenu'):[];
    try{
        const bytes = CryptoJS.AES.decrypt(permissionenc, keyEcncrypt);
        const permissionsuser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let idx = permissionsuser.indexOf('');
        return permissionsuser;
    }catch(err){
        // window.location.href = '/';
        return [];
    }
}
export const reloadToHomeNotAuthorize = (listPermission,action)  =>{
    // CheckMenuAuthorize(listPermission,action);
    let flagRenewPassword = localStorage.getItem('shouldRenewPassword') == 'true'?true:false;
    if(flagRenewPassword){
        // localStorage.removeItem('shouldRenewPassword');
        window.location.href = '/';
    }
    const flag =  isGetPermissions(listPermission,action);
    if(flag){
        window.location.href = '/unauthorized';
    }
}
// export const CheckMenuAuthorize = (listPermission,action)  =>{
//     const history = useHistory();
//     const flag =  isGetPermissions(listPermission,action);
//     if(flag){
//         history.push(pathmenu.unauthorized);
//     }
// }
export const isGetPermissions = (listPermissionMenu,action)  =>{
    //true = hide
    //false = show
    //parameter action tidak dipakai
    
    let listPermissionTemp = getPermissions();
    if(listPermissionTemp.indexOf('ALL_FUNCTIONS') > -1){
        return false;
    }else if(listPermissionTemp.indexOf('ALL_FUNCTIONS_READ') > -1){
        for (var i = 0; i < listPermissionMenu.length; i++) {
            let subPermission = listPermissionMenu[i].substring(0,5);
            if(subPermission !== 'READ_'){
                if(listPermissionTemp.indexOf(listPermissionMenu[i]) > -1){
                    continue;
                }else{
                    return true;
                }
                
            }
        }
        return false;
    }
    
    if(listPermissionMenu.length > 0){
        let countPermission = 0;
        for (var i = 0; i < listPermissionMenu.length; i++) {
            if(listPermissionTemp.indexOf(listPermissionMenu[i]) > -1){
                countPermission++;
            }
        }
        return !(listPermissionMenu.length == countPermission);
    }
    return true;
    
}

export const isGetPortalMenuPermission = (listportalmenu,listportalgrup)  =>{

    let list = getPortalMenuPermissions();
    // console.log('list ',list)
    if (list.length == 0) return true;
    if (list.indexOf('ALLMENU') > -1) return false;
    if(listportalmenu.length > 0){
        let countPermission = 0;
        for (var i = 0; i < listportalmenu.length; i++) {
            if(list.indexOf(listportalmenu[i]) > -1){
                countPermission++;
            }
        }

        // console.log('listportalmenu.length ',listportalmenu.length)
        // console.log('listportalmenu ',countPermission+" | "+listportalmenu.length+' | '+listportalmenu)

        // if(listportalgrup.length > 0 && !(listportalmenu.length !== countPermission)){
        if(listportalgrup.length > 0 && listportalmenu.length !== countPermission){
            let countPortalgrup = 0;
            for (var i = 0; i < listportalgrup.length; i++) {
                if(list.indexOf(listportalgrup[i]) > -1){
                    countPortalgrup++;
                }
            }
            // console.log('listportalgrup ',countPortalgrup+" | "+listportalgrup.length)
            return !(listportalgrup.length == countPortalgrup);
        }
       

        return !(listportalmenu.length == countPermission);
    }else{
        if(listportalgrup.length > 0){
            let countPortalgrup = 0;
            for (var i = 0; i < listportalgrup.length; i++) {
                if(list.indexOf(listportalgrup[i]) > -1){
                    countPortalgrup++;
                }
            }
            return !(listportalgrup.length == countPortalgrup);
        }
    }
    return false;
}
export const msgErrorHandler = (data,param)  =>{
    let parameter = new Object();
    if(param == undefined){
    }else if(param == null){
    }else{
        parameter = param;
    }
    return handleMappingMessage(data,parameter);
}

export const listMsgError = (code,lang)  =>{
    let teks = '';
    if(code === 'error.msg.password.already.used'){
        if(lang === 'en'){
            teks = 'Password Has Been Used';
        }else{
            teks = 'Password Sudah Pernah Digunakan';
        }
        
    }else if(code === 'error.msg.password.already.used'){
        if(lang === 'en'){
            teks = 'Repetition of Entering the Password Does not Match the Password';
        }else{
            teks = 'Pengulangan Memasukan Kata Sandi Tidak Sesuai Dengan Kata Sandi';
        }
    }else if(code === 'error.msg.client.close.loan.non-closed'){
        if(lang === 'en'){
            teks = 'Member cannot be closed because of non-closed financing';
        }else{
            teks = 'Anggota tidak dapat ditutup karena pembiayaan belum ditutup';
        }
    }else if(code === 'error.msg.client.close.non-closed.savings.account'){
        if(lang === 'en'){
            teks = 'Member cannot be closed because of non-closed savings account';
        }else{
            teks = 'Anggota tidak dapat ditutup karena simpanan belum ditutup';
        }
    }else if(code === 'validation.msg.clientImage.cannot.be.blank'){
        if(lang === 'en'){
            teks = 'Please upload your Bank Transfer Receipt';
        }else{
            teks = 'Mohon upload bukti transfer Anda';
        }
    }else if(code === 'error.msg.CoreTransactionCodeDetail.data.duplicate'){
        teks = 'Transaction Code with These Details is Already Exists';
    }else if(code === 'error.msg.clientjob.currently.used.duplicate'){
        teks = 'Employment Information With Currently Used True Already Exist';
    }else if(code === 'validation.msg.savingsaccount.overdraftLimit.cannot.exceed.product.value'){
        teks = 'Overdraft Limit Cannot Be More Than Overdraft Limit in Product Setting';
    }
    return teks;
}

export const getuserid = ()  =>{
    const encUserid = localStorage.getItem('userId') ? localStorage.getItem('userId'):'';
    if(encUserid != null && encUserid != undefined && encUserid !== ''){
        const bytes = CryptoJS.AES.decrypt(encUserid, keyEcncrypt);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }
    return '';
}

export const msgErrorHandlerCode = (data)  =>{
    
    let flag = data.data?.errors?true:false;
    let text = [];
    if(flag){
        let arrErrors = data.data.errors;
        
        if(arrErrors.length > 0){
            for(var i=0; i< arrErrors.length; i++){
                text.push('error_'+arrErrors[i].userMessageGlobalisationCode.replaceAll('.','_'));
            }
            
        }
    }
    text = text.length == 0 ?['label_UNKNOWN_ERROR']:text;
    return text;
}
export const getdataLocal = (key)  =>{
    const data = localStorage.getItem(key);
    if(data != null && data != undefined){
        return data;
    }else{
        return '';
    }
}
export const getValInLocalStorageEnc = (key)  =>{
    const encVal = localStorage.getItem(key) ? localStorage.getItem(key):'';
    if(encVal != null && encVal != undefined && encVal !== ''){
        try{
        const bytes = CryptoJS.AES.decrypt(encVal, keyEcncrypt);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
        }catch(error){
            return '';
        }
    }
    return '';
}

export const checkValueNumberMax3 = (value)  =>{
    let strVal = new String(value).replaceAll('.','').replaceAll(',','.');
    let valFloat = Number(strVal);
    if(!isNaN(valFloat)){
        if(valFloat > 100){
            return false;
        }
    }
    return true;

}

export const msgErrorHandlerMapping = (data)  =>{
    let flag = data.data?.errors?true:false;
    if(flag){
        let arrErrors = data.data.errors;
        if(arrErrors.length > 0){
            if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.charge.due.at.disbursement.cannot.be.penalty'){
                return {code:'error_msg_charge_due_at_disbursement_cannot_be_penalty',usermsg:arrErrors[0].defaultUserMessage}
            }else{
                let tempcode = arrErrors[0].userMessageGlobalisationCode;
                return {code:tempcode.replaceAll('.','_'),usermsg:arrErrors[0].defaultUserMessage}
            }
        }
    }
}
export const checkErrorHandlerMapping = (error,mapping)  =>{
    var flag = error.includes("_");
    if(flag){
        return mapping.usermsg;
    }else{
        return error;
    }
}
export const substringValue = (from,to,value)  =>{
    try{
        var str = value.substring(from, to);
        return str;
    }catch(err){
        return '';
    }
    
}

export const operationsDate = (date,type,operations,val)  =>{
    //harus new Date();
    if(type == 'date'){
        let tempdate = date;
            if(operations == '-'){
                tempdate.setDate(tempdate.getDate() - parseInt(val));
            }else if(operations == '+'){
                tempdate.setDate(tempdate.getDate() + parseInt(val));
            }
            return tempdate;
    }else if(type == 'month'){
        let tempdate = date;
            if(operations == '-'){
                tempdate.setMonth(tempdate.getMonth() - parseInt(val));
            }else if(operations == '+'){
                tempdate.setMonth(tempdate.getMonth() + parseInt(val));
            }
            return tempdate;
    }else if(type == 'year'){
        let tempdate = date;
            if(operations == '-'){
                tempdate.setFullYear(tempdate.getFullYear() - parseInt(val));
            }else if(operations == '+'){
                tempdate.setFullYear(tempdate.getFullYear() + parseInt(val));
            }
            return tempdate;
    }
}

export const manyCheckingForHide = (arrayParam,permission)  =>{
    if(arrayParam.indexOf(true) > -1){
        if(permission !== null){
            return permission;
        }
        return false;
    }else{
        return true;
    }
}
export const checkLang = (param_lang,label_lang,label_ori)  =>{
    if(param_lang == label_lang){
        return label_ori;
    }else{
        return label_lang;
    }
}

export const pos_to_neg = (num)  =>{
    return -Math.abs(num);
}

export const addDefaultImageErrorSrc = (ev) => {
    return ev.target.src = '/img/Simpool_Full_Logo_mobile.png'
}

export const listMonthAndYear = (years) => {
    let obj = new Object();

    let datamonth = [];
    let month = {value:1,label:'January'} 
    datamonth.push(month);
    month = {value:2,label:'February'};
    datamonth.push(month);
    month = {value:3,label:'Maret'};
    datamonth.push(month);
    month = {value:4,label:'April'};
    datamonth.push(month);
    month = {value:5,label:'Mei'};
    datamonth.push(month);
    month = {value:6,label:'Juni'};
    datamonth.push(month);
    month = {value:7,label:'Juli'};
    datamonth.push(month);
    month = {value:8,label:'Agustus'};
    datamonth.push(month);
    month = {value:9,label:'September'};
    datamonth.push(month);
    month = {value:10,label:'Oktober'};
    datamonth.push(month);
    month = {value:11,label:'November'};
    datamonth.push(month);
    month = {value:12,label:'Desember'};
    datamonth.push(month);

    // let years = moment().year();
    let datayears = [];

    //let month = {value:0,label:'January'}
    for(var i=0; i < 40 ; i++){
        let tempyears = years - i;
        let temp = {value:tempyears,label:tempyears}
        datayears.push(temp);
    }
    obj.listmonth = datamonth;
    obj.listyears = datayears;
    return obj;
}

export const timeFormat24HoursPM = (time,type) => {
    let splittime = new String(time).split(':');
    let jam = splittime[0];
    let menit = splittime[1];
    let detik = splittime[2];

    if(type == 'AM'){
        if(!isNaN(jam)){
            if(parseInt(jam) == 12){
                jam = '00';
            }
        }
    }
    if(type == 'PM'){
        if(!isNaN(jam)){
            if(parseInt(jam) >= 1 && parseInt(jam) < 12 ){
                jam = parseInt(jam) + 12;
            }
        }
    }
    

    return jam+':'+menit+':'+detik;
    
}

export const checkDate = (from,to,current) => {
    //YYYY-MM-DD format Tanggal
    let dateCurrArr = new String(current).split('-');
    let yearCurr = dateCurrArr[0];
    let monthCurr = dateCurrArr[1];
    let dateCurr = dateCurrArr[2];

    let dateFromArr = new String(from).split('-');
    let yearFrom = dateFromArr[0];
    let monthFrom = dateFromArr[1];
    let dateFrom = dateFromArr[2];

    let obj = new Object();
    if(parseInt(yearCurr) == parseInt(yearFrom)){
        if(parseInt(monthFrom) > parseInt(monthCurr)){
            obj.from = from;
            obj.to = from;
        }else if(parseInt(monthCurr) == parseInt(monthFrom)){
            obj.from = from;
            obj.to = current;
        }else if(parseInt(monthFrom) < parseInt(monthCurr) ){
            obj.from = from;
            obj.to = to;
        }else{
            obj.from = from;
            obj.to = to;
        }
    }else if(parseInt(yearCurr) > parseInt(yearFrom)){
        obj.from = from;
        obj.to = to;
    }else{
        obj.from = from;
        obj.to = to;
    }

    return obj;
}

export const getRolesinSession = ()  =>{
    const roleenc = localStorage.getItem('listroles') ? localStorage.getItem('listroles'):[];
    try{
        const bytes = CryptoJS.AES.decrypt(roleenc, keyEcncrypt);
        const roleDecr = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let idx = roleDecr.indexOf('');
        return roleDecr;
    }catch(err){
        // window.location.href = '/';
        return [];
    }
}

export const isGetRolesCheck = (listRoles, isOneOfThem)  =>{
    let roleList = getRolesinSession();
    if(listRoles.length > 0){
        let countPermission = 0;
        for (var i = 0; i < listRoles.length; i++) {
            if(roleList.indexOf(listRoles[i]) > -1){
                countPermission++;
            }
        }
        //isOneOfThem = true a/ Jika Salah Satu Role Sama Maka Lolos
        //isOneOfThem = false a/ Semua Roles yang di set Harus Sama
        if(isOneOfThem){
            return !(countPermission > 0);
        }
        return !(listRoles.length == countPermission);
    }
    return true;
}

export const addDays = (date, days)  =>{
    //const date = new Date(); *Harus new Date*
    return date.setDate(date.getDate() + days);
}