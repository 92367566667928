import {createStore, applyMiddleware, compose} from 'redux';
import reducers                                from './reducers/reducers';
import middlewares                             from './middlewares/middlewares'

import createSagaMiddleware from 'redux-saga';
import {
    watchAuth, watchMember
    , watchMemberDetail
    , watchMemberEdit
    , watchSavingDetail
    , watchSaving
    , watchDeposit
    , watchDashboard
    , watchLoan
    , watchAccounting
    , watchMobileUser
    , watchMerchant
    , watchToken
    , watchGeneral
    , watchMenuManagement
    , watchUserManagement
    , watchSweepAccount
    , watchOrganisation
    , wacthSystem
    , wacthProduct
    , watchMobile
}                           from "./sagas";
import {routerMiddleware} from 'connected-react-router';
import {history} from './history';

let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    composeEnhancers = compose;
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middlewares, routerMiddleware(history), sagaMiddleware))
);

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchMember);
sagaMiddleware.run(watchMemberDetail);
sagaMiddleware.run(watchMemberEdit);
sagaMiddleware.run(watchSavingDetail);
sagaMiddleware.run(watchSaving);
sagaMiddleware.run(watchDeposit);
sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchLoan);
sagaMiddleware.run(watchAccounting);
sagaMiddleware.run(watchMobileUser);
sagaMiddleware.run(watchMerchant);
sagaMiddleware.run(watchToken);
sagaMiddleware.run(watchGeneral);
sagaMiddleware.run(watchMenuManagement);
sagaMiddleware.run(watchUserManagement);
sagaMiddleware.run(watchSweepAccount);
sagaMiddleware.run(watchOrganisation);
sagaMiddleware.run(wacthSystem);
sagaMiddleware.run(wacthProduct);
sagaMiddleware.run(watchMobile);

export default store;
