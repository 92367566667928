import axios        from '../../Axios-Simpool';
import {
    glAccountUrl,glAccountByIdUrl,updateAndDeleteURL,
    getAccountingRulesUrl,paymentTypesURL,jurnalEntriesURL,listAccountRulesURL,
    AccountRulesTemplateURL,submitAccountingRulesURL,getDetailAccountingRulesURL,AccountingRulesURL,
    seacrhJournalEntriesByParamURL,baseTransactionModeURL,baseTransactionCodeURL,baseChargesURL,baseTransactionCodeDetailURL,baseTransactionCodeDetailOverDraftURL,
    baseTransactionCodeLimitURL,basedTransactionCodeControlUrl,glRecurringTemplateURL,baseGLRecurringURL,
    feeByTrxModeChargeProductURL,glSummaryParameterdUrl,reportJobUrl,interbranchSettlementUrl

}                   from '../../shared/apiUrl';
import { toLogout,msgErrorHandler } from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* getListGLAccountSaga(action) {
    try {
        const response = yield axios.get(glAccountUrl, {
        }).then(response => response.data)
            action.successHandler(response,'api');
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getGLAccountSaga(action) {
    try {
        const response = yield axios.get(glAccountUrl+"/"+action.id, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getGLTemplateSaga(action) {
    try {
        const response = yield axios.get(glAccountUrl+"/template", {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getGLTemplateWithTypeSaga(action) {
    try {
        const response = yield axios.get(glAccountUrl+"/template?type="+action.typeid, {
        }).then(response => response.data)
        action.successHandler(response);
}catch (error) {
    toLogout(error);
    const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
    action.errorHandler(errMessages.join('\n'));
}
}
export function* getAccountingRulesSaga(action) {
    try {
        const response = yield axios.get(getAccountingRulesUrl, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitGLAccountSaga(action) {
    try {
        const response = yield axios.post(glAccountUrl,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(error);
    }
}

export function* getGlAccountByIDSaga(action) {
    try {
        const response = yield axios.get(glAccountByIdUrl(action.id), {
        }).then(response => response.data)
        action.successHandler(response);
}catch (error) {
    toLogout(error);
    const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
    action.errorHandler(errMessages.join('\n'));
}
}
export function* getPaymentTypesSaga(action) {
    try {
        const response = yield axios.get(paymentTypesURL, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitJurnalEntriesSaga(action) {
    try {
        const response = yield axios.post(jurnalEntriesURL,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getListAccountRulesSaga(action) {
    try {
        const response = yield axios.get(listAccountRulesURL, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* updateGLAccountSaga(action) {
    try {
        const response = yield axios.put(updateAndDeleteURL(action.id),action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        console.log('error ',error)
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(error);
    }
}

export function* deleteGLAccountSaga(action) {
    try {
        const response = yield axios.delete(updateAndDeleteURL(action.id)).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}
export function* getAccountRulesTemplateSaga(action) {
    try {
        const response = yield axios.get(AccountRulesTemplateURL, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitAccountingRulesSaga(action) {
    try {
        const response = yield axios.post(submitAccountingRulesURL,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}


export function* getDetailAccountRulesSaga(action) {
    try {
        const response = yield axios.get(getDetailAccountingRulesURL(action.id), {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* deleteAccountRulesSaga(action) {
    try {
        const response = yield axios.delete(AccountingRulesURL+'/'+action.id, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getDetailAccountRulesWithTemplateSaga(action) {
    try {
        const response = yield axios.get(getDetailAccountingRulesURL(action.id)+"?template=true", {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* updateAccountingRulesSaga(action) {
    try {
        const response = yield axios.put(submitAccountingRulesURL+'/'+action.id,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getSearchJournalEntriesByParamSaga(action) {
    try {
        const response = yield axios.get(seacrhJournalEntriesByParamURL(action.queryparam), {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getRecurringTemplateSaga(action) {
    try {
        const response = yield axios.get(glRecurringTemplateURL, {
            }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getListTransactionModeSaga(action) {
    try {
        const response = yield axios.get(baseTransactionModeURL+action.queryparam, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitGlRecurringSaga(action) {
    try {
        const response = yield axios.post(baseGLRecurringURL,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}
export function* submitTransactionModeSaga(action) {
    try {
        const response = yield axios.post(baseTransactionModeURL,action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitEditTransactionModeSaga(action) {
    try {
        const response = yield axios.put(baseTransactionModeURL+'/'+action.id,action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitDeleteTransactionModeSaga(action) {
    try {
        const response = yield axios.delete(baseTransactionModeURL+'/'+action.id, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getListTransactionCodeSaga(action) {
    try {
        const response = yield axios.get(baseTransactionCodeURL+action.queryparam, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getListChargesSaga(action) {
    try {
        const response = yield axios.get(baseChargesURL+action.queryparam, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitTransactionCodeSaga(action) {
    try {
        const response = yield axios.post(baseTransactionCodeURL,action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitDeleteTransactionCodeSaga(action) {
    try {
        const response = yield axios.delete(baseTransactionCodeURL+'/'+action.id, {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getDataTransactionCodeSaga(action) {
    try {
        const response = yield axios.get(baseTransactionCodeURL+action.queryparam, {
        }).then(response => response.data)
            action.successHandler(response,action.param);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getDataTransactionModeSaga(action) {
    try {
        const response = yield axios.get(baseTransactionModeURL+action.queryparam, {
        }).then(response => response.data)
            action.successHandler(response,action.param);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitEditTransactionCodeSaga(action) {
    try {
        const response = yield axios.put(baseTransactionCodeURL+'/'+action.id,action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitDeleteTransactionCodeDetailSaga(action) {
    try {
        const response = yield axios.post(baseTransactionCodeDetailURL(action.id),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getTransactionCodeDetailSaga(action) {
    try {
        const response = yield axios.get(baseTransactionCodeDetailURL(action.param), {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitTransactionCodeDetailSaga(action) {
    try {
        const response = yield axios.post(baseTransactionCodeDetailURL(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditTransactionCodeDetailSaga(action) {
    try {
        const response = yield axios.put(baseTransactionCodeDetailURL(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitTransactionCodeDetailOverDraftSaga(action) {
    try {
        const response = yield axios.post(baseTransactionCodeDetailOverDraftURL(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditTransactionCodeDetailOverDraftSaga(action) {
    try {
        const response = yield axios.put(baseTransactionCodeDetailOverDraftURL(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getTransactionCodeLimitSaga(action) {
    try {
        const response = yield axios.get(baseTransactionCodeLimitURL(action.param), {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitAddTransactionCodeLimitSaga(action) {
    try {
        const response = yield axios.post(baseTransactionCodeLimitURL(''),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteTransactionCodeLimitSaga(action) {
    try {
        const response = yield axios.delete(baseTransactionCodeLimitURL(action.param)).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditTransactionCodeLimitSaga(action) {
    try {
        const response = yield axios.put(baseTransactionCodeLimitURL(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitTransactionCodeControlSaga(action) {
    try {
        const response = yield axios.post(basedTransactionCodeControlUrl(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getFeeTrxModeChargesProductSaga(action) {
    try {
        const response = yield axios.get(feeByTrxModeChargeProductURL(action.param), {
        }).then(response => response.data)
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getGLSummaryParameterDataSaga(action) {
    try {
        const response = yield axios.get(glSummaryParameterdUrl(action.param), {
        }).then(response => response.data)
            action.successHandler(response,action.actiontype);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitAddGLSummaryParameterSaga(action) {
    try {
        const response = yield axios.post(glSummaryParameterdUrl(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditGLSummaryParameterSaga(action) {
    try {
        const response = yield axios.put(glSummaryParameterdUrl(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteGLSummaryParameterSaga(action) {
    try {
        const response = yield axios.delete(glSummaryParameterdUrl(action.param)).then(response => response.data);
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getReportJobSaga(action) {
    try {
        const response = yield axios.get(reportJobUrl(action.param)).then(response => response.data);
            action.successHandler(response,action.actiontype);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getInterBranchSettlementSaga(action) {
    try {
        const response = yield axios.get(interbranchSettlementUrl(action.param)).then(response => response.data);
            action.successHandler(response,action.actiontype);
    }catch (error) {
        toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitInterBranchSettlementSaga(action) {
    try {
        if(action.actiontype == "POST"){
            const response = yield axios.post(interbranchSettlementUrl(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
        }else if(action.actiontype == "PUT"){
            const response = yield axios.put(interbranchSettlementUrl(action.param),action.payload).then(response => response.data);
            action.successHandler(response);
        }
        
    }catch (error) {
        toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}