import {MenuMember,MenuSavings,MenuTermSavings,MenuMobileUser,
        MenuFinancingApproval,MenuFinancingDisbursement,
        MenuSettlementVA,MenuToken,ReportMemberList,ReportSavingsBalanceSummary,
        ReportSavingList,ReportTermSavingList,ReportTermSavingSummary,ReportActiveFinancing,
        ReportInstallmentPayment,ReportMonthlyLoanInstallmentList,ReportTrialBalance,ReportIncomeStatement,
        ReportBalanceSheet,ReportGeneralLedger,MenuDashboard,ReportFinancingCollectibility,ReportFinancingProvosioning, Portal_Dashboard, Portal_Member, Portal_Approval, Portal_Cashier, Portal_Token, Portal_Biller, Portal_Accounting, Portal_Report, Portal_Setting, Portal_Setting_organisation, Portal_Setting_product, Portal_Setting_accounting, Portal_Setting_mobile, Portal_Setting_message, Portal_Setting_token, Portal_Setting_user, Portal_Setting_security, Portal_Setting_externalservice, Portal_Setting_system,
        MenuPaylater,ReportMemberPaylaterAccSummary,MenuCreateDepositBiller,MenuIntermediary,MenuBlockAccount,ReportTransactionBillerByMonth,ReportTransactionBillerByYears,ReportTransactionBillerByCode,MenuMemberApproval,MenuCreateJournalRecurring_Permission,MenuUnitUser,Portal_Data_Import,Portal_Teller,Portal_HeadTeller,MenuAccountingGeneralTrx, MenuLoanSubmission, MenuPeriodicReport, MenuSavingsPlan, ReportTabunganBerjangkaAktif_permission, ReportTabunganBerjangkaJatuhTempo_permission, ReportTabunganBerjangkaElapsed_permission, ReportTabunganBerjangkaCairTutup_permission, MenuSpesialToken,
        MenuMerchantList, MenuMerchantApprove, MenuAgent,MenuTransferMemberApproval, Portal_MemberData, Portal_Member_Savings, Portal_Member_AutoSweep, Portal_Member_Paylater, Portal_Member_TermSaving, Portal_Member_SavingPlan, Portal_Member_Financing, Portal_Member_MobileUser, Portal_Member_BlockingAccount, Portal_Member_Agent, Portal_Member_Merchant, Portal_CoopUnit, Portal_CoopUnit_UnitData, Portal_CoopUnit_UnitUser, Portal_CoopUnit_Intermediary, Portal_Accounting_FreqPosting, Portal_Accounting_GeneralTrx, Portal_Accounting_AccJournal, Portal_Accounting_InterbranchSettlement, Portal_Report_Member, Portal_Report_Savings, Portal_Report_SavingPlan, Portal_Report_Paylater, Portal_Report_Teller, Portal_Report_Intermediary, Portal_Report_TermSaving, Portal_Report_Financing, Portal_Report_Accounting, Portal_Report_Periodic, Portal_Approval_FinancingApproval, Portal_Approval_FinancingDisbursement, Portal_Approval_FinancingSubmission, Portal_Approval_MemberApproval, Portal_Approval_MemberActivation, Portal_Approval_MemberTransferApproval, Portal_Approval_MerchantApproval,
        MenuFinancingCollectionSheet,
        Portal_Member_CollectionAgent,
        Portal_Report_Agent,
        Portal_Approval_FinancingApproval_single} from './shared/PermissionForFeatures'
import * as pathmenu           from './shared/pathMenu';
import { isGetPortalMenuPermission} from './shared/maskFunc';
const Menu = [
    {
        heading: 'Main Navigation',
        translate: 'sidebar.heading.HEADER',
        portalmenu:[]
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'icon-dashboard',
        translate: 'sidebar.nav.DASHBOARD',
        permissions:MenuDashboard,
        type:'READ',
        portalmenu:Portal_Dashboard
    },
    {
        name: 'Member',
        icon: 'icon-people',
        translate: 'sidebar.nav.member.MEMBERS',
        permissions:[],
        type:'READ',
        portalgrup: Portal_Member,
        portalmenu:Portal_Member,
        submenu: [{
            name: 'Member Data',
            path: '/member',
            translate: 'sidebar.nav.member.MEMBER_DATA',
            portalgrup: Portal_Member,
            permissions:MenuMember,
            type:'READ',
            portalmenu: Portal_MemberData,
        },
            {
                name: 'Savings',
                path: '/savings',
                translate: 'sidebar.nav.member.SAVING_DATA',
                permissions:MenuSavings,
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_Savings
            },
            {
                name: 'Sweep Account',
                path: pathmenu.sweepaccount,
                translate: 'sidebar.nav.member.SWEEPACCOUNT',
                permissions:[],
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_AutoSweep
            },{
                name: 'Paylater',
                path: pathmenu.listPaylater,
                translate: 'label_PAYLATER',
                permissions:MenuPaylater,
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_Paylater
            },
            // {
            //     name: 'Intermediary',
            //     path: pathmenu.intermediary,
            //     translate: 'label_INTERMEDIARY',
            //     permissions:MenuIntermediary,
            //     portalgrup:Portal_Member,
            //     type:'READ',
            //     portalmenu:[]
            // },
            {
                name: 'Fix Deposit',
                path: '/fixdeposit',
                translate: 'sidebar.nav.member.TERMDEPOSIT',
                permissions:MenuTermSavings,
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_TermSaving
            },
            {
                name: 'Saving Plan',
                path: pathmenu.menuRecurring,
                translate: 'label_SAVING_PLAN',
                permissions:MenuSavingsPlan,
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_SavingPlan
            },
            {
                name: 'Loan Data',
                path: pathmenu.loanListMenu,
                translate: 'sidebar.nav.member.LOAN_DATA',
                permissions:[],
                portalgrup:Portal_Member,
                // permissions:MenuFinancing,
                type:'READ',
                portalmenu:Portal_Member_Financing
            },
            {
                name: 'User Data',
                path: '/MobileUser',
                translate: 'label_MOBILE_USER',
                permissions:MenuMobileUser,
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_MobileUser
            },
            {
                name: 'Blocking Account',
                path: pathmenu.menublockedaccount,
                translate: 'label_BLOCKED_ACCOUNT',
                permissions:MenuBlockAccount,
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_BlockingAccount
            },
            {
                name: 'Agent',
                path: pathmenu.menuagent,
                translate: 'Agent',
                permissions:MenuAgent,
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_Agent
            },
            {
                name: 'CollectionAgent',
                path: pathmenu.collectionAgent,
                translate: 'label_COLLECTIONAGENT',
                permissions:MenuFinancingCollectionSheet,
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_CollectionAgent
            },
            {
                name: 'Merchant',
                path: pathmenu.merchantList,
                translate: 'Merchant',
                permissions:MenuMerchantList,
                portalgrup:Portal_Member,
                type:'READ',
                portalmenu:Portal_Member_Merchant
            },
            // {
            //     name: 'Merchant ReSubmit',
            //     path: pathmenu.merchantReSubmit,
            //     translate: 'Merchant ReSubmit',
            //     permissions:MenuMerchantList,
            //     portalgrup:Portal_Member,
            //     type:'READ',
            //     portalmenu:[]
            // }
            


        ]
    },
    {
        name: 'Coop Unit',
        icon: 'icon-coopunit',
        translate: 'Coop Unit',
        permissions:[],
        type:'READ',
        portalgrup:Portal_CoopUnit,
        portalmenu:Portal_CoopUnit,
        submenu: [
            {
                name: 'Unit Data',
                path: pathmenu.menuUnitData,
                translate: 'Unit Data',
                permissions:MenuMember,
                portalgrup:Portal_CoopUnit,
                type:'READ',
                portalmenu:Portal_CoopUnit_UnitData,
                label:{
                    color:'red'
                }
            },
            {
                name: 'Unit User',
                path: pathmenu.menuUnitUser,
                translate: 'Unit User',
                permissions:MenuUnitUser,
                portalgrup:Portal_CoopUnit,
                type:'READ',
                portalmenu:Portal_CoopUnit_UnitUser,
                label:{
                    color:'red'
                }
            },
            {
                name: 'Intermediary',
                path: pathmenu.intermediary,
                translate: 'label_INTERMEDIARY',
                permissions:MenuIntermediary,
                portalgrup:Portal_CoopUnit,
                type:'READ',
                portalmenu:Portal_CoopUnit_Intermediary
            }
        ]
    },
    {
        name: 'Approval',
        icon: 'icon-approval',
        translate: 'sidebar.nav.approval.TITTLE',
        permissions:[],
        type:'READ',
        portalgrup:Portal_Approval,
        portalmenu:Portal_Approval,
        submenu: [
            {
                name: 'Financing Approval',
                path: '/approval/financing-approval',
                translate: 'sidebar.nav.approval.LOAN_APPROVAL',
                permissions:MenuFinancingApproval,
                portalgrup:Portal_Approval,
                type:'READ',
                portalmenu:Portal_Approval_FinancingApproval,
                label:{
                    color:'red'
                }
            },
            {
                name: 'Financing Approval-Single',
                path: pathmenu.financingapprovalsingle,
                translate: 'sidebar.nav.approval.LOAN_APPROVAL_SINGLE',
                permissions:MenuFinancingApproval,
                portalgrup:Portal_Approval,
                type:'READ',
                portalmenu:Portal_Approval_FinancingApproval_single,
                label:{
                    color:'red'
                }
            },
            {
                name: 'Financing Disbursement',
                path: '/approval/financing-disbursment',
                translate: 'sidebar.nav.approval.LOAN_DISBURSMENT',
                permissions:MenuFinancingDisbursement,
                portalgrup:Portal_Approval,
                type:'READ',
                portalmenu:Portal_Approval_FinancingDisbursement,
                label:{
                    color:'red'
                }
            },
            {
                name: 'Financing Submission',
                path: pathmenu.financingsubmission,
                translate: 'label_LIST_FINANCING_SUBMISSION',
                permissions:MenuLoanSubmission,
                portalgrup:Portal_Approval,
                type:'READ',
                portalmenu:Portal_Approval_FinancingSubmission,
                label:{
                    color:'red'
                }
            },
            {
                name: 'Member Approval',
                path: pathmenu.memberapproval,
                translate: 'label_MEMBER_APPROVAL',
                permissions:MenuMemberApproval,
                portalgrup:Portal_Approval,
                type:'READ',
                portalmenu:Portal_Approval_MemberApproval,
                label:{
                    color:'red'
                }
            },
            {
                name: 'List Member Activation',
                path: pathmenu.listmemberactivation,
                translate: 'label_LIST_MEMBER_ACTIVATION',
                permissions:MenuMemberApproval,
                portalgrup:Portal_Approval,
                type:'READ',
                portalmenu:Portal_Approval_MemberActivation,
                label:{
                    color:'red'
                }
            },
            {
                name: 'Transfer Member Approval',
                path: pathmenu.memberApproveRejectTransfer,
                translate: 'label_TRANSFER_MEMBER_APPROVAL',
                permissions:MenuTransferMemberApproval,
                portalgrup:Portal_Approval,
                type:'READ',
                portalmenu:Portal_Approval_MemberTransferApproval,
                label:{
                    color:'red'
                }
            },{
                name: 'Merchant Approval',
                path: pathmenu.merchantApproval,
                translate: 'Merchant Approval',
                permissions:MenuMerchantApprove,
                portalgrup:Portal_Approval,
                type:'READ',
                portalmenu:Portal_Approval_MerchantApproval,
                label:{
                    color:'red'
                }
            },
            
        ]

    },
    {
        name: 'Cashier',
        icon: 'icon-cashier',
        translate: 'label_CASHIER',
        permissions:[],
        type:'READ',
        portalmenu:isGetPortalMenuPermission(Portal_Teller,[])?Portal_HeadTeller:Portal_Teller,
        submenu: [
            // {
            //     name: 'Transaction',
            //     path: pathmenu.cashiertransaction,
            //     translate: 'label_TRANS_CASHIER',
            //     permissions:[],
            //     type:'READ',
            //     portalmenu:[],
            //     portalgrup:Portal_Cashier,
            //     label:{
            //         color:'red'
            //     }
            // },{
            //     name: 'Transaction Approval',
            //     path: pathmenu.transactiontellerapproval,
            //     translate: 'Transaction Approval',
            //     permissions:[],
            //     type:'READ',
            //     portalmenu:[],
            //     portalgrup:Portal_Cashier,
            //     label:{
            //         color:'red'
            //     }
            // },
            {
                name: 'Cashir',
                path: pathmenu.menucashier,
                translate: 'label_CASHIER',
                permissions:[],
                type:'READ',
                portalmenu:[],
                portalgrup: [],//isGetPortalMenuPermission(Portal_Teller,[])?Portal_HeadTeller:Portal_Teller,
                label:{
                    color:'red'
                }
            },
            // {
            //     name: 'Cashier Management',
            //     path: pathmenu.comingsoon,
            //     translate: 'label_MANAGEMENT_CASHIER',
            //     permissions:[],
            //     type:'READ',
            //     portalmenu:[],
            //     portalgrup:Portal_Cashier,
            //     label:{
            //         color:'red'
            //     }
            // }
        ]

    },
    {
        name: 'Token',
        icon: 'icon-token',
        translate: 'sidebar.nav.token.TITTLE',
        permissions:[],
        type:'READ',
        portalgrup:Portal_Token,
        portalmenu:Portal_Token,
        submenu: [
            {
                name: 'Token Service',
                path: '/token/tokenservice',
                translate: 'sidebar.nav.token.TOKEN_SERVICE',
                permissions:MenuToken,
                portalgrup:Portal_Token,
                type:'READ',
                portalmenu:[]
            },
            {
                name: 'Special Token',
                path: pathmenu.specialToken,
                translate: 'label_SPECIAL_TOKEN',
                permissions:MenuSpesialToken,
                portalgrup:Portal_Token,
                type:'READ',
                portalmenu:[]
            }
        ]

    },
    {
        name: 'Biller',
        icon: 'icon-biller',
        translate: 'sidebar.nav.biller.TITTLE',
        permissions:[],
        portalgrup:Portal_Biller,
        portalmenu:Portal_Biller,
        submenu: [
            {
                name: 'Deposit Biller',
                path: pathmenu.createdepositbiller,
                translate: 'label_DEPOSIT_BILLER',
                permissions:MenuCreateDepositBiller,
                type:'TRANSACTION',
                portalmenu:[],
                portalgrup:Portal_Biller,
            },
            // {
            //     name: 'Deposit Biller',
            //     path: '/biller/depositbiller',
            //     translate: 'sidebar.nav.biller.DEPOSIT_BILLER',
            //     permissions:MenuDepositBiller,
            //     type:'TRANSACTION',
            //     portalmenu:[],
            //     portalgrup:Portal_Biller,
            // },
            {
                name: 'Settlement VA',
                path: pathmenu.menuCreateSettlementIncoming,//'/biller/settlementva',
                translate: 'label_SETTLEMENT_VA',
                permissions:MenuSettlementVA,
                type:'TRANSACTION',
                portalmenu:[],
                portalgrup:Portal_Biller,
            }
        ]
    },
    // {
    //     name: 'Transaction',
    //     icon: 'icon-basket',
    //     translate: 'sidebar.nav.transaction.TRANSACTIONS',
    //     submenu: [{
    //         name: 'Deposit',
    //         path: '/transaction/deposit',
    //         translate: 'sidebar.nav.transaction.DEPOSIT'
    //     },
    //         {
    //             name: 'Withdrawal',
    //             path: '/transaction/withdrawal',
    //             translate: 'sidebar.nav.transaction.WITHDRAWAL'
    //         },
    //         {
    //             name: 'Loan Payment',
    //             path: '/transaction/loan-payment',
    //             translate: 'sidebar.nav.transaction.LOAN_PAYMENT'
    //         },
    //         {
    //             name: 'Transfer',
    //             path: '/transaction/transfer',
    //             translate: 'sidebar.nav.transaction.TRANSFER'
    //         }
    //     ]
    // },
    {
        name: 'Accounting',
        icon: 'icon-accounting',
        translate: 'sidebar.nav.accountancy.ACCOUNTANCIES',
        permissions:[],
        portalgrup:Portal_Accounting,
        portalmenu:Portal_Accounting,
        submenu: [
        // {
        //     name: 'Chart Of Account',
        //     path: '/accounting/coa',
        //     translate: 'sidebar.nav.accountancy.REGISTER_ACCOUNT_CODE',
        //     permissions:[],
        // },
            {
            name: 'Frequent Posting',
            path: '/accounting/addfrequentposting',
            translate: 'sidebar.nav.accountancy.FREQUENPOSTING',
            permissions:[],
            portalmenu:Portal_Accounting_FreqPosting,
            portalgrup:Portal_Accounting,
            },
            {
                name: 'General Transaction',
                path: pathmenu.menugeneraltransaction,
                translate: 'General Transaction',
                permissions:MenuAccountingGeneralTrx,
                portalmenu:Portal_Accounting_GeneralTrx,
                portalgrup:Portal_Accounting,
            },
            {
                name: 'Accounting Journal',
                path: pathmenu.accountingJournal,
                translate: 'Accounting Journal',
                permissions:[],
                portalmenu:Portal_Accounting_AccJournal,
                portalgrup:Portal_Accounting,
            },
            {
                name: 'Interbranch Settlement',
                path: pathmenu.interBranchSettlement,
                translate: 'label_INTERBRANCHSETTLEMENT',
                permissions:[],
                portalmenu:Portal_Accounting_InterbranchSettlement,
                portalgrup:Portal_Accounting,
            },
            // {
            //     name: 'Journal Entries',
            //     path: pathmenu.addFormJournalEntries,
            //     translate: 'Journal Entries',
            //     permissions:[],
            //     portalmenu:[],
            //     portalgrup:Portal_Accounting,
            // },
            // {
            //     name: 'Recurring Journal Entries',
            //     path: pathmenu.createGlRecurring,
            //     translate: 'Recurring Journal Entries',
            //     permissions:MenuCreateJournalRecurring_Permission,
            //     portalmenu:[],
            //     portalgrup:Portal_Accounting,
            // },
            // {
            //     name: 'Search Journal Entries',
            //     path: pathmenu.searchJournalEntries+'/search',
            //     translate: 'Search Journal Entries',
            //     permissions:[],
            //     portalmenu:[],
            //     portalgrup:Portal_Accounting,
            // },
            // {
            //     name: 'Search Recurring Journal',
            //     path: pathmenu.comingsoon,
            //     translate: 'Search Recurring Journal',
            //     permissions:[],
            //     portalmenu:[],
            //     portalgrup:Portal_Accounting,
            // },
            // {
            //     name: 'Periodic Report',
            //     path: pathmenu.PeriodicReport,
            //     translate: 'label_PERIODICREPORT',
            //     permissions:MenuPeriodicReport,
            //     portalmenu:[],
            //     portalgrup:Portal_Accounting,
            // }
            // ,
            // {
            //     name: 'Journal Entry',
            //     path: '/accountancy/journal-entry',
            //     translate: 'sidebar.nav.accountancy.JOURNAL_ENTRY'
            // },
            // {
            //     name: 'Beginning Balance',
            //     path: '/accounting/beginning-balance',
            //     translate: 'sidebar.nav.accountancy.NEW_ACCOUNT_BALANCE'
            // },
            // {
            //     name: 'Transaction Code',
            //     path: '/accountancy/transaction-code',
            //     translate: 'sidebar.nav.accountancy.TRANSACTION_CODE'
            // }
        ]
    },
    {
        name: 'Report',
        icon: 'icon-report',
        translate: 'sidebar.nav.report.REPORTS',
        permissions:[],
        portalgrup:Portal_Report,
        portalmenu:Portal_Report,
        type:'READ',
        submenu: [
            {
            name: 'Member',
            path: '/reports/reportmember',
            translate: 'sidebar.nav.report.MEMBER',
            type:'READ',
            portalmenu:Portal_Report_Member,
            portalgrup:Portal_Report,
            permissions:[
                {
                    report:'MemberList',
                    permissionsreport:ReportMemberList,
                    portalmenu:[]
                }
            ]
        },
            {
                name: 'Saving',
                path: '/reports/reportsavings',
                translate: 'sidebar.nav.report.SAVING',
                type:'READ',
                portalmenu:Portal_Report_Savings,
                portalgrup:Portal_Report,
                permissions:[
                    {
                        report:'SavingBalanceSummary',
                        permissionsreport:ReportSavingsBalanceSummary,
                        portalmenu:[]
                    },
                    {
                        report:'SavingList',
                        permissionsreport:ReportSavingList,
                        portalmenu:[]
                    }
                ]
            },
            {
                name: 'SavingPlan',
                path: pathmenu.reportRecurring,
                translate: 'label_SAVING_PLAN',
                type:'READ',
                portalmenu:Portal_Report_SavingPlan,
                portalgrup:Portal_Report,
                permissions:[
                    {
                        report:'ReportTabunganBerjangkaAktif',
                        permissionsreport:ReportTabunganBerjangkaAktif_permission,
                        portalmenu:[]
                    },
                    {
                        report:'ReportTabunganBerjangkaJatuhTempo',
                        permissionsreport:ReportTabunganBerjangkaJatuhTempo_permission,
                        portalmenu:[]
                    },
                    {
                        report:'ReportTabunganBerjangkaElapsed',
                        permissionsreport:ReportTabunganBerjangkaElapsed_permission,
                        portalmenu:[]
                    },
                    {
                        report:'ReportTabunganBerjangkaCairTutup',
                        permissionsreport:ReportTabunganBerjangkaCairTutup_permission,
                        portalmenu:[]
                    }
                ]
            }
            ,{
                name: 'Paylater',
                path: pathmenu.reportPaylaterList,
                translate: 'label_PAYLATER',
                type:'READ',
                portalmenu:Portal_Report_Paylater,
                portalgrup:Portal_Report,
                permissions:[
                    {
                        report:'ReportMemberPaylaterAccSummary',
                        permissionsreport:ReportMemberPaylaterAccSummary,
                        portalmenu:[]
                    }
                ]
            },
            {
                name: 'Teller',
                path: pathmenu.reportTeller,
                translate: 'Teller',
                type:'READ',
                portalmenu:Portal_Report_Teller,
                portalgrup:Portal_Report,
                permissions:[
                    {
                        report:'ReportMemberPaylaterAccSummary',
                        permissionsreport:ReportMemberPaylaterAccSummary,
                        portalmenu:[]
                    }
                ]
            },
            {
                name: 'Intermediary',
                path: pathmenu.reportIntermediaryList,
                translate: 'label_INTERMEDIARY',
                type:'READ',
                portalmenu:Portal_Report_Intermediary,
                portalgrup:Portal_Report,
                permissions:[
                    {
                        report:'ReportMemberIntermediaryAccSummary',
                        permissionsreport:ReportMemberPaylaterAccSummary,
                        portalmenu:[]
                    },
                    {
                        report:'ReportMemberIntermediaryAccSummary',
                        permissionsreport:ReportMemberPaylaterAccSummary,
                        portalmenu:[]
                    }
                ]
            },
            {
                name: 'TermSaving',
                path: '/reports/reporttermsavings',
                translate: 'sidebar.nav.report.TERMSAVINGS',
                type:'READ',
                portalmenu:Portal_Report_TermSaving,
                portalgrup:Portal_Report,
                permissions:[
                    {
                        report:'TermSavingList',
                        permissionsreport:ReportTermSavingList,
                        portalmenu:[]
                    },
                    {
                        report:'TermSavingSummary',
                        permissionsreport:ReportTermSavingSummary,
                        portalmenu:[]
                    }
                ]
            },{
                name: 'Financies',
                path: '/reports/reportfinancings',
                translate: 'sidebar.nav.report.FINANCINGS',
                type:'READ',
                portalmenu:Portal_Report_Financing,
                portalgrup:Portal_Report,
                permissions:[
                    {
                        report:'ActiveFinancing',
                        permissionsreport:ReportActiveFinancing,
                        portalmenu:[]
                    },
                    {
                        report:'InstallmentPayment',
                        permissionsreport:ReportInstallmentPayment,
                        portalmenu:[]
                    },
                    {
                        report:'MonthlyLoanInstalmentList',
                        permissionsreport:ReportMonthlyLoanInstallmentList,
                        portalmenu:[]
                    },
                    {
                        report:'FinancingCollectibility',
                        permissionsreport:ReportFinancingCollectibility,
                        portalmenu:[]
                    },
                    {
                        report:'FinancingProvosioning',
                        permissionsreport:ReportFinancingProvosioning,
                        portalmenu:[]
                    }
                ]
            },
            {
                name: 'Accountancy',
                path: '/reports/reportaccounting',
                translate: 'sidebar.nav.report.ACCOUNTANCY',
                type:'READ',
                portalmenu:Portal_Report_Accounting,
                portalgrup:Portal_Report,
                permissions:[
                    {
                        report:'TrialBalance',
                        permissionsreport:ReportTrialBalance,
                        portalmenu:[]
                    },
                    {
                        report:'IncomeStatement',
                        permissionsreport:ReportIncomeStatement,
                        portalmenu:[]
                    },
                    {
                        report:'BalanceSheet',
                        permissionsreport:ReportBalanceSheet,
                        portalmenu:[]
                    },
                    {
                        report:'GeneralLedger',
                        permissionsreport:ReportGeneralLedger,
                        portalmenu:[]
                    }
                ]
            },
            {
                name: 'Periodic Report',
                path: pathmenu.PeriodicReport,
                translate: 'label_PERIODICREPORT',
                permissions:MenuPeriodicReport,
                portalmenu:Portal_Report_Periodic,
                portalgrup:Portal_Report,
            },
            {
                name: 'ReportBiller',
                path: pathmenu.reportBiller,
                translate: 'sidebar.nav.report.BILLER',
                type:'READ',
                portalmenu:[],
                portalgrup:Portal_Report,
                permissions:[
                    {
                        report:'TransactionBillerMonth',
                        permissionsreport:ReportTransactionBillerByMonth,
                        portalmenu:[]
                    },
                    {
                        report:'TransactionBillerYear',
                        permissionsreport:ReportTransactionBillerByYears,
                        portalmenu:[]
                    },
                    {
                        report:'TransactionBillerCode',
                        permissionsreport:ReportTransactionBillerByCode,
                        portalmenu:[]
                    }
                ]
            },
            {
                name: 'Agent',
                path: pathmenu.reportagent,
                translate: 'Agent',
                type:'READ',
                portalmenu:Portal_Report_Agent,
                portalgrup:Portal_Report,
                permissions:[
                    // {
                    //     report:'MemberList',
                    //     permissionsreport:ReportMemberList,
                    //     portalmenu:[]
                    // }
                ]
            },
            
        ]
    }
    ,
    {
        name: 'Data Import',
        icon: 'icon-dataimport',//'icon-notebook',
        translate: 'Data Import',
        permissions:[],
        portalgrup:Portal_Data_Import,
        portalmenu:Portal_Data_Import,
        type:'READ',
        submenu: [
            {
                name: 'Bulk Transaction',
                path: pathmenu.bulktransaction,
                translate: 'Bulk Transaction',
                permissions:[],
                portalgrup:Portal_Data_Import,
                portalmenu:Portal_Data_Import,
            }
        ]
    },
    {
        name: 'Setting',
        icon: 'icon-equalizer',
        translate: 'sidebar.nav.setting.SETTINGS',
        permissions:[],
        portalgrup:Portal_Setting,
        portalmenu:Portal_Setting,
        submenu: [
        // {
        //     name: 'Frequent Posting',
        //     path: '/setting/accounting/accountingrules',
        //     translate: 'sidebar.nav.accountancy.FREQUENPOSTING',
        //     permissions:[],
        // },
        {
            name: 'Organisation',
            path: pathmenu.organisation,
            translate: 'label_ORGANISATION',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_organisation,
        },
        {
            name: 'Products',
            path: pathmenu.settingproducts,
            translate: 'label_PRODUCT',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_product
        },
        {
            name: 'Accounting',
            path: pathmenu.settingaccounting,
            translate: 'label_ACCOUNTING',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_accounting
        },
        {
            name: 'Mobile Management',
            path: pathmenu.settingmobile,
            translate: 'label_MOBILE_MANAGEMENT',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_mobile
        },
        {
            name: 'Messaging Management',
            path: pathmenu.settingmessaging,
            translate: 'label_MESSAGE_MANAGEMENT',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_message
        },
        {
            name: 'Token',
            path: pathmenu.menuSettingToken,
            translate: 'Token',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_token
        },
        {
            name: 'User Management',
            path: pathmenu.settinguser,
            translate: 'label_USER_MANAGEMENT',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_user
        },
        {
            name: 'Security Management',
            path: pathmenu.settingsecurity,
            translate: 'sidebar.nav.setting.SECURITY_MANAGEMENT',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_security
        },
        {
            name: 'External Service',
            path: pathmenu.settingexternal,
            translate: 'label_EXTERNAL_SERVICE',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_externalservice
        },
        {
            name: 'System',
            path: pathmenu.settingsystem,
            translate: 'System',
            permissions:[],
            portalgrup:Portal_Setting,
            portalmenu:Portal_Setting_system
        },
        
        // ,
        //     {
        //         name: 'Loan Products',
        //         path: '/setting/loan-products',
        //         translate: 'sidebar.nav.setting.LOAN_PRODUCTS'
        //     },
        //     {
        //         name: 'Charge Products',
        //         path: '/setting/charge-products',
        //         translate: 'sidebar.nav.setting.CHARGE_PRODUCTS'
        //     },
        //     {
        //         name: 'Service Office List',
        //         path: '/setting/service-office-list',
        //         translate: 'sidebar.nav.setting.SERVICE_OFFICE_LIST'
        //     },
        //     {
        //         name: 'Biller Management',
        //         path: '/setting/biller-management',
        //         translate: 'sidebar.nav.setting.BILLER_MANAGEMENT'
        //     },
        //     {
        //         name: 'Role and Permissions',
        //         path: '/setting/role-and-permissions',
        //         translate: 'sidebar.nav.setting.ROLE_AND_PERMISSIONS'
        //     },
        //     {
        //         name: 'Checker Maker',
        //         path: '/setting/checker-marker',
        //         translate: 'sidebar.nav.setting.CHECKER_MARKER'
        //     },
        //     {
        //         name: 'Template',
        //         path: '/setting/template',
        //         translate: 'sidebar.nav.setting.TEMPLATE'
        //     }
        ]
    }
];

export default Menu;