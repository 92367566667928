import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    dashboardGenderUrl,
    dashboardAgeUrl,
    dashboardProvinceUrl,
    dashboardClientMonthUrl,
    dashboardLoanMonthUrl,
    dashboardTotalApexURL,
    dashboardMemberApexURL,
    dashboardCityApexURL,
    dashboardClientTenantApexURL,
    dashboardGeoCityApexURL,dashboardSyncronizeApexURL,
    dashboardActiveMobileUserApexURL,dashboardGenderApexUrl,dashboardAgeApexUrl,dashboardLoanApexUrl,
    dashboardLoanDisburseApexUrl
}
                    from '../../shared/apiUrl';
import { toLogout } from '../../shared/maskFunc';

export function* fetchDashboardGenderSaga(action) {
    yield put(actions.fetchDashboardGenderStart());
    try {
        const response = yield axios.get(dashboardGenderUrl).then(response => response.data);
        yield put(actions.fetchDashboardGenderSuccess());
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        yield put(actions.fetchDashboardGenderFailed(error));
        action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardAgeSaga(action){
    yield put(actions.fetchDashboardAgeStart());
    try {
        const response = yield axios.get(dashboardAgeUrl).then(response => response.data);
        yield put(actions.fetchDashboardAgeSuccess());
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        yield put(actions.fetchDashboardAgeFailed(error));
        action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardProvinceSaga(action){
    yield put(actions.fetchDashboardProvinceStart());
    try {
        const response = yield axios.get(dashboardProvinceUrl).then(response => response.data);
        yield put(actions.fetchDashboardProvinceSuccess());
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        yield put(actions.fetchDashboardProvinceFailed(error));
        action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardClientMonthSaga(action){
    yield put(actions.fetchDashboardClientMonthStart());
    try {
        const response = yield axios.get(dashboardClientMonthUrl).then(response => response.data);
        yield put(actions.fetchDashboardClientMonthSuccess());
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        yield put(actions.fetchDashboardClientMonthFailed(error));
        action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardLoanSaga(action) {
    yield put(actions.fetchDashboardLoanrStart());
    try {
        const response = yield axios.get(dashboardLoanMonthUrl).then(response => response.data);
        yield put(actions.fetchDashboardLoanSuccess());
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        yield put(actions.fetchDashboardLoanFailed(error));
        action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardTotalApexSaga(action){
    try {
        const response = yield axios.get(dashboardTotalApexURL).then(response => response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardMemberApexSaga(action){
    try {
        const response = yield axios.get(dashboardMemberApexURL).then(response => response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardCityApexSaga(action){
    try {
        const response = yield axios.get(dashboardCityApexURL).then(response => response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardClientTenantApexSaga(action){
    try {
        const response = yield axios.get(dashboardClientTenantApexURL).then(response => response);
        console.log('response ',response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardGeoCityTenantApexSaga(action){
    try {
        const response = yield axios.get(dashboardGeoCityApexURL).then(response => response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}

export function* fetchDashboardSyncApexSaga(action){
    try {
        const response = yield axios.get(dashboardSyncronizeApexURL).then(response => response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);//error.data.message ?? error.message);
    }
}

export function* fetchDashboardActiveMobileUserApexSaga(action){
    try {
        const response = yield axios.get(dashboardActiveMobileUserApexURL).then(response => response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}

export function* getDashboardAgeSaga(action){
    try {
        const response = yield axios.get(dashboardAgeApexUrl).then(response => response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        console.log('getDashboardAgeSaga ',error)
        const errMessages = yield error.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Terjadi Kesalahan';
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}

export function* getDashboardGenderSaga(action){
    try {
        const response = yield axios.get(dashboardGenderApexUrl).then(response => response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        console.log('getDashboardGenderSaga ',error)
        const errMessages = yield error.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Terjadi Kesalahan';
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}

export function* getDashboardLoanSaga(action){
    try {
        const response = yield axios.get(dashboardLoanApexUrl).then(response => response);
        action.successCallback(response.data);
    } catch (error) {
        toLogout(error);
        console.log('getDashboardLoanDisburseSaga ',error)
        const errMessages = yield error.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Terjadi Kesalahan';
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}

export function* getDashboardLoanDisburseSaga(action){
    try {
        const response = yield axios.get(dashboardLoanDisburseApexUrl).then(response => response);
        action.successCallback(response.data,action.param);
    } catch (error) {
        toLogout(error);
        console.log('getDashboardLoanDisburseSaga ',error)
        const errMessages = yield error.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Terjadi Kesalahan';
        action.errorCallback(errMessages);
        // action.errorCallback(error.data.message ?? error.message);
    }
}