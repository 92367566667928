import { ajax } from 'jquery';
import { numToMoney } from './maskFunc';
import { maxDataCSV } from './constantValue';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const pingURL = (URL) => {
  let param = new Object();
  let settings = {

    // Defines the configurations
    // for the request
    cache: false,
    dataType: "jsonp",
    // dataType:"text/html",
    async: true,
    global: false,
    crossDomain: true,
    url: URL,
    type: 'POST',
    // method: "GET",
    // method: "POST",
    data: {},
    headers: {
      //   accept: "application/json",
      accept: "text/html",
      "Access-Control-Allow-Origin": "*",
    },
    // Defines the response to be made
    // for certain status codes
    statusCode: {
      200: function (response) {
        // console.log("Status 200: Page is up!");
        param.code = 200;
      },
      400: function (response) {
        // console.log("Status 400: Page is down.");
        param.code = 400;
      },
      404: function (response) {
        // console.log("Status 404: Not Found.");
        param.code = 404;
      },
      503: function (responseObject, textStatus, errorThrown) {
        // Service Unavailable (503)
        // This code will be executed if the server returns a 503 response
        // console.log("Status 503: Not Found.");
        param.code = 503;
      },
      0: function (response) {
        // console.log("Status 0: Page is down.");
        param.code = 0;
      },
    },
    // error:function (xhr) {
    //     console.log("xhr: ",xhr);
    // },
    // error: function (jqXHR, textStatus, errorThrown) {
    //     // alert(textStatus, errorThrown);
    //         console.log("jqXHR: ",jqXHR);
    //         console.log("textStatus: ",textStatus);
    //         console.log("errorThrown: ",errorThrown);
    // },
    success: function (data) {
      // console.log('success ',data);
    },
    //   complete: function(xhr, textStatus) {
    //     console.log("complete ",xhr);
    // } 

  };

  // Sends the request and observes the response
  ajax(settings).done(function (response) {
    // console.log(response);
    // console.log("done : ",response);
  })
    //   .fail(function(jqXHR, textStatus){
    //     alert('Something went wrong: ' + textStatus);
    // })
    //   .always(function(jqXHR, textStatus) {
    //     alert('Ajax request was finished')
    //  });
    //   .fail(function(jqXhr) {
    //     console.log("Fail : ",jqXhr);
    //     // toastr.error('There is some errors in your request');
    //   })
    ;

  return new Promise((resolve, reject) => {

    setTimeout(() => {

      resolve(param);

    }, 700);

  });
  //   setTimeout(() => {

  //     return param;

  //   }, 3000);
  //   return param;

}

export const flagConnection = (param) => {
  console.log('flagConnection ', param);
  let flag = false;
  if (param.code) {
    if (param.code == 200) {
      flag = true;
    }
  }
  return flag;
}

export const checkParameter = (value, param) => {
  let flag = true;
  if (value == undefined || value == null) {
    flag = false;
  }
  return flag;
}

export const handleSetFormatMoney = (header, column, columnType) => {
  let val = column;
  if (!isNaN(column)) {
    if (columnType == 'numeric') {
      val = numToMoney(parseFloat(column));
    }
  }
  return val;
}

export const handleSetWidth = (column) => {
  let val = '200';
  // if(column == 'Nomor Rekening Pembiayaan'){
  //     val = '250';
  // }
  return val;
}

const setListCSV = (value) => {
  let listvalue = [];
  for (let x = 0; x < value.length; x++) {
    let tempval = value[x] == null || value[x] == undefined ? '""' : '"' + new String(value[x]).replaceAll(/\n/g, " ") + '"';
    listvalue.push(tempval);
  }
  return listvalue;
}
export const downloadDataCSV = (param) => {
  let data = param.data;
  let isdownloadfile = param.isdownloadfile ? param.isdownloadfile : true;
  if (data) {
    if (data.columnHeaders && data.data) {
      const headerCSV = data.columnHeaders.reduce((obj, el) => [
        ...obj,
        '"' + el.columnName + '"'
      ], []);

      let valCSV = [headerCSV];
      for (let i = 0; i < data.data.length; i++) {
        let value = data.data[i].row;
        valCSV.push(setListCSV(value));
      }

      if (isdownloadfile) {
        let namafile = param.namafile ? param.namafile + '.csv' : 'NoNameFile.csv';
        let CSV = valCSV.map(e => e.join(",")).join("\n");//ValueCSV.map(e => e.join(",")).join("\n");
        let contentType = 'text/csv';
        let csvFile = new Blob([CSV], { type: contentType });
        var dataUrl = URL.createObjectURL(csvFile);

        var fileLink = document.createElement('a');
        fileLink.href = dataUrl;

        // it forces the name of the downloaded file
        fileLink.download = namafile;
        fileLink.click();
        fileLink.remove();
      }

      let retObj = new Object();
      retObj.header = headerCSV;
      retObj.datacsv = valCSV;
      return retObj;
    }
  }

  return null;
}
export const processDataCSV = (param) => {
  let data = param.data;
  let useMaxData = param.useMaxData !== undefined?param.useMaxData:true;
  // let isfilecsv = param.isfilecsv ?param.isfilecsv:true;
  if (data) {
    if (data.columnHeaders && data.data) {
      const headerCSV = data.columnHeaders.reduce((obj, el) => [
        ...obj,
        '"' + el.columnName + '"'
      ], []);

      const valcolumns = data.columnHeaders.reduce((obj, el) => [
        ...obj,
        {
          'name': el.columnName.replaceAll(' ', ''),
          'title': el.columnName,
        }
      ], []);
      valcolumns.push({ 'name': 'id', 'title': 'ID' });

      const valtableColumnExtensions = data.columnHeaders.reduce((obj, el) => [
        ...obj,
        {
          'columnName': el.columnName.replaceAll(' ', ''),
          'width': handleSetWidth(el.columnName),
          'sortingEnabled': false
        }
      ], []);

      let theData = [];
      let valCSV = [headerCSV];
      let datalength = data.data.length;
      for (let i = 0; i < datalength; i++) {
        if (maxDataCSV > 0 && maxDataCSV == i && useMaxData) {
          break;
        }
        let value = data.data[i].row;
        // if(isfilecsv){
        //   valCSV.push(setListCSV(value));
        // }
        let valObj = {};
        for (let j = 0; j < data.columnHeaders.length; j++) {
          let hdr = data.columnHeaders[j];
          valObj[hdr.columnName.replaceAll(' ', '')] = handleSetFormatMoney(hdr.columnName, value[j], hdr.columnType);
        }

        valObj['id'] = i + 1;
        theData.push(valObj);
      }

      let retObj = new Object();
      retObj.header = headerCSV;
      retObj.columns = valcolumns;
      retObj.columnsExtensions = valtableColumnExtensions;
      retObj.datagrid = theData;
      retObj.datacsv = valCSV;
      retObj.datalength = datalength;
      retObj.flagDownloadPDF = datalength > maxDataCSV && maxDataCSV !== -1 && useMaxData? false : true;
      return retObj;
    }
  }
  return null;
}

export const textDialogHtml = (param) => {
  let valArr = [];
  if (param !== null && param !== undefined) {
    let text = param.text ? param.text : '';
    valArr.push('<p >' + text + '</p>');
  }
  return valArr;
}

export const getErrorJsonData = (param) => {
  let errorsData = param.errors ? param.errors : [];
  let defaultMsg = param.defaultMsg ? param.defaultMsg : '';
  let codeErr = param.codeErr ? param.codeErr : '';

  let flagErrArr = errorsData.data?.errors ? true : false;
  let flagErr = errorsData.data ? true : false;
  let codeErrsAPI = '';
  let defaultMsgAPI = '';
  let val1 = '';
  let val2 = '';
  let val3 = '';
  let parameterName = '';
  if (flagErrArr) {
    let arrErrors = errorsData.data.errors;
    if (arrErrors.length > 0) {
      val1 = arrErrors[0]?.args[0] ? arrErrors[0].args[0].value : '?';
      val2 = arrErrors[0]?.args[1] ? arrErrors[0].args[1].value : '?';
      val3 = arrErrors[0]?.args[2] ? arrErrors[0].args[2].value : '?';
      parameterName = arrErrors[0]?.parameterName ? arrErrors[0].parameterName : '?';

      codeErrsAPI = arrErrors[0].userMessageGlobalisationCode;
      defaultMsgAPI = arrErrors[0].defaultUserMessage;
    }
  } else if (flagErr) {
    let errors = errorsData.data;

    codeErrsAPI = errors.userMessageGlobalisationCode;
    defaultMsgAPI = errors.defaultUserMessage;
  } else if (errorsData === codeErr) {

  }

  let retObj = new Object();
  retObj.codeErr = codeErrsAPI == '' ? new String(codeErr).replaceAll(".", "_") : new String(codeErrsAPI).replaceAll(".", "_");
  retObj.codeErrAPI = codeErrsAPI == '' ? codeErr : codeErrsAPI;
  retObj.defaultMsg = defaultMsgAPI == '' ? defaultMsg : defaultMsgAPI;
  retObj.val1 = val1;
  retObj.val2 = val2;
  retObj.val3 = val3;
  retObj.parameterName = parameterName;

  return retObj;
}


export const parsingErrorJsonData = (param) => {
  let msg = param.msg ? param.msg : '';
  let errJson = param.errJson ? param.errJson : [];
  if (msg == errJson.codeErr) {
    return errJson.defaultMsg == '' ? errJson.codeErr : errJson.defaultMsg + ' (' + errJson.codeErrAPI + ')';
  }
  return msg;
}


export const mappingStatusMerchant = (value) => {
  let status = value;
  if (status == 'Waiting Approval') {
    status = 'STATUS_MERCHANT.label_WAITING_APPROVAL';
  } else if (status == 'Waiting Approval Apex') {
    status = 'STATUS_MERCHANT.label_WAITING_APPROVAL_APEX';
  } else if (status == 'Approve') {
    status = 'STATUS_MERCHANT.label_APPROVED';
  } else if (status == 'Active') {
    status = 'STATUS_MERCHANT.label_ACTIVE';
  } else if (status == 'Rejected by Mitra') {
    status = 'STATUS_MERCHANT.label_REJECTED_BY_COOP';
  } else if (status == 'Rejected by Apex') {
    status = 'STATUS_MERCHANT.label_REJECTED_BY_APEX';
  } else if (status == 'Rejected') {
    status = 'STATUS_MERCHANT.label_REJECTED';
  }

  return status;
}
export const handlePrintIframe = (dataUrl) => {


  let iframe = document.createElement('iframe');//document.createElement('iframe'); //load content in an iframe to print later
  // document.getElementById("myDIV").appendChild(iframe);
  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.type = 'application/pdf'
  iframe.src = dataUrl;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
}

export const alertDialog = (param) => {
    const error = param.error;
    const parami18n = param.parami18n ? param.parami18n:{};
    const Swal = param.Swal;
    const i18n = param.i18n;
    const type = param.type;
    if(type == "ERROR"){
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: parsingErrorJsonData({msg:i18n.t(error.codeErr,parami18n),errJson:error})
      })
    }
}

export const timeFormat24HoursPM = (time,type) => {
  let splittime = new String(time).split(':');
  let jam = splittime[0];
  let menit = splittime[1];
  let detik = splittime[2];

  if(type == 'AM'){
      if(!isNaN(jam)){
          if(parseInt(jam) == 12){
              jam = '00';
          }
      }
  }
  if(type == 'PM'){
      if(!isNaN(jam)){
          if(parseInt(jam) >= 1 && parseInt(jam) < 12 ){
              jam = parseInt(jam) + 12;
          }
      }
  }
  

  return jam+':'+menit+':'+detik;
  
}

export const dateTimeFormat24Hours = (moment, param) => {
    let value = param.value;
    let type = param.type?param.type:'';
    if(checkParameter(value)){
      if(new String(value).includes('PM')){
          if(type == 'ONLY-TIME'){
            return timeFormat24HoursPM(moment(value).format('hh:mm:ss'),'PM');
          }
          return moment(value).format('DD MMM YYYY')+" "+timeFormat24HoursPM(moment(value).format('hh:mm:ss'),'PM');
      }else if(new String(value).includes('AM')){
        if(type == 'ONLY-TIME'){
          return timeFormat24HoursPM(moment(value).format('hh:mm:ss'),'AM');
        }
          return moment(value).format('DD MMM YYYY')+" "+timeFormat24HoursPM(moment(value).format('hh:mm:ss'),'AM');
      }else{
          return value;
      }
  }
  return "";
}

export const convertToBetweenTwoDateMiliseconds = (param) => {
    const date1 = new Date(param.dateval1);
    const date2 = new Date(param.dateval2);

    const diffTime = Math.abs(date2 - date1);
    return diffTime;
    // const diffTimeSec = Math.abs(date2 - date1) / 1000;
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    // console.log(diffTime + " milliseconds");
    // console.log(convertMsToTime(diffTime) + " convertMsToTime");
    // console.log(diffTimeSec + " seconds");
    // console.log(diffDays + " days");
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const convertMsToTime = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
  
    seconds = seconds % 60;
    minutes = minutes % 60;
  
    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    let obj = new Object();
    obj.hours = padTo2Digits(hours);
    obj.minutes = padTo2Digits(minutes);
    obj.seconds = padTo2Digits(seconds);

    return obj;
    // return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    //   seconds,
    // )}`;
}